<template>
    <section
    ref="dashboard"
    class="EmailVerifiedPage"
  >

 <one-column>
    <template #content>
        <div class="container contentRight">
          <div class="row">
            <div class="column">
              <img src="@/assets/images/medel-logo.svg" />
              <h1>Vielen Dank!</h1>
              <p>Ihr myMED-EL Account wird nun eingerichtet. Sie werden in den nächsten Minuten ein Email zum setzen ihres Passworts erhalten.</p>
              <p>Wenn sie mit dieser Email Adresse bereits einen myMED-EL Service nutzen, werden Sie eine Email zur neuerlichen Bestätigung der Adresse erhalten.</p>
              <p><link-router path="home">Zum Login</link-router></p>
            </div>
          </div>
        </div>

      </template>
    </one-column>

  </section>
</template>

<script>
import OneColumn from '@/layouts/OneColumn.vue'

export default {
  name: 'Onboarding',

  components: {
    OneColumn
  },

  metaInfo () {
    return {
      title: 'myMED-EL Registrierung'
    }
  },

  data () {
    return {
    }
  },

  computed: {
  },

  async mounted () {
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

.EmailVerifiedPage {
  padding-top: 0px;
  min-height: calc(100vh - 20px);
}

.content {
  display: flex;
  flex-flow: column wrap;
  padding-top: 50px;
}

.row {
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
}

.column {
  display: flex;
  flex-flow: column;
  align-items: center;
}

img {
    width: none;
    flex: 0 1 auto;
    max-width: 200px;
    align-self: center;
  }

.contentRight {
  padding-top: 20px;
  display: flex;
}

</style>
